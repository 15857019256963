<template>

  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader :title="$t('salesInstallmentsPayments.salesInstallmentsPayments')"
                     :description="$t('salesInstallmentsPayments.fromHereYouCanControlThesalesInstallmentsPayments')"/>
         <indexTable :searchText="$t('salesInstallmentsPayments.FindABatch')"
                     :emptyTableText="$t('salesInstallmentsPayments.thereAreNosalesInstallmentsPayments')"
                     :emptyTableSubText="$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain')"
                     :buttonRole="false"
                     :cloumns="[
                         {column: 'code',    title: $t('salesInstallmentsPayments.batch') ,type: 'mainLink' , sort: true },
                         {column: 'client_type',    title: $t('salesInstallmentsPayments.customerName') ,type: 'clintLink', sort: true},
                         {column: 'safe_id',    title:$t('salesInstallmentsPayments.Treasury'),      type: 'link', to:'safe', sort: true, link: true},
                         {column: 'date',    title: $t('salesInstallmentsPayments.paymentDate') ,type: 'text' , sort: true },
                         {column: 'sales_installment_id',    title: $t('salesInstallmentsPayments.salesInstallment') ,type: 'linksalesInstallment', to:'salesInstallment', sort: true, link: true},
                         {column: 'description',    title: $t('salesInstallmentsPayments.thatsAbout') ,type: 'text' , sort: true },
                         {column: 'cost',    title: $t('salesInstallmentsPayments.amount') ,type: 'text' , sort: true },
                         {column: 'options', title: $t('salesInstallmentsPayments.settings'), type: 'options', options: [
                             {name: 'show'},
                             {name: 'edit',   role: $user.admin || $user.role.sales_payments_edit},
                             {name: 'file'},
                             {name: 'delete', role: $user.admin || $user.role.sales_payments_delete},
                         ]}
                       ]"
                     :deleteText="{
                        attention: $t('allerts.Attention'),
                        areYouReallySureToDeleteTheItem: $t('allerts.areYouReallySureToDeleteTheItem'),
                        close: $t('allerts.close'),
                        confirm: $t('allerts.confirm'),
                        loading: $t('allerts.loading'),
                       }" />
      </div>
    </div>
  </div>

</template>
<script>

  import axios from 'axios'

  import indexHeader from '../elements/index/indexHeader.vue'
  import indexTable from '../elements/index/indexTable.vue'

  export default {
    data() {
      return {
        path: '/salesInstallmentsPayments',
        items: [],

        awaitingSearch: false,
        delete_id: null,
        totalPages: 0,
        loading: 0,

        params: {},
      }
    },
    methods: {
      getItems() {
        this.loading = 1
        this.totalPages = 0
        this.items = []
        this.$setParams(this.params)
        axios.get(this.$linkGnirator(this.path), { params: this.params }).then(response => {
          this.items = response.data.data
          if (this.$parent.localDatabase.salesInstallmentsPayments.length != 0) {
            var localItems = JSON.parse(JSON.stringify(this.$parent.localDatabase.salesInstallmentsPayments))
            var items = localItems.concat(this.items);
            this.items = items;
          }
          this.totalPages = response.data.last_page
          this.params.page = response.data.current_page
          this.loading = 0          
          
          localStorage.salesInstallmentsPaymentsCashe = JSON.stringify(response.data)
        })
      },
      deleteItem() {
        axios
          .post(this.$linkGnirator(this.path + '/' + this.items[this.delete_id].id), {
            _method: 'DELETE'
          })
          .then(response => {
            this.getItems()
            this.delete_id = null
            this.$parent.model = 0
            return response
          })
      }
    },
    watch: {
      'params.search': function() {
        clearTimeout(this.awaitingSearch)
        this.awaitingSearch = setTimeout(() => {
          this.getItems()
        }, 400)
      },
      '$parent.stopUpload.salesInstallmentsPayments': function(val) {
        if (!val) {
          this.getItems()
          if (localStorage.getItem('salesInstallmentsPaymentsCashe') !== null) {
            this.loading = 0
            var data = JSON.parse(localStorage.getItem('salesInstallmentsPaymentsCashe'))
            this.items = data.data
            this.totalPages = data.last_page
            this.page = data.current_page
          }
        }
      },
      items: function () {
        this.items.forEach(item => {
          item.date = this.$dateFormat(item.date)
          item.cost = this.$moneyFormat(item.cost)
          var customer = this.$database.customers.find(el => el.id == item.customer_id)
          item.customer = customer;
          var supplier = this.$database.suppliers.find(el => el.id == item.supplier_id)
          item.supplier = supplier;
          var safe = this.$database.safes.find(el => el.id == item.safe_id)
          item.safe = safe;
        })
      }
    },
    mounted() {
      this.params = this.$getParams()
      if (this.$route.params.id) {
        this.printInvoice = this.$route.params.id
        this.$router.replace(this.path)
      }
      this.getItems()
      if (localStorage.getItem('salesInstallmentsPaymentsCashe') !== null) {
        this.loading = 0
        var data = JSON.parse(localStorage.getItem('salesInstallmentsPaymentsCashe'))
        this.items = data.data
        this.totalPages = data.last_page
        this.page = data.current_page
      }
    },
    components: {
      indexHeader,
      indexTable,
    }
  }

</script>
