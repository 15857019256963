var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('salesInstallmentsPayments.salesInstallmentsPayments'),"description":_vm.$t('salesInstallmentsPayments.fromHereYouCanControlThesalesInstallmentsPayments')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('salesInstallmentsPayments.FindABatch'),"emptyTableText":_vm.$t('salesInstallmentsPayments.thereAreNosalesInstallmentsPayments'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":false,"cloumns":[
                       {column: 'code',    title: _vm.$t('salesInstallmentsPayments.batch') ,type: 'mainLink' , sort: true },
                       {column: 'client_type',    title: _vm.$t('salesInstallmentsPayments.customerName') ,type: 'clintLink', sort: true},
                       {column: 'safe_id',    title:_vm.$t('salesInstallmentsPayments.Treasury'),      type: 'link', to:'safe', sort: true, link: true},
                       {column: 'date',    title: _vm.$t('salesInstallmentsPayments.paymentDate') ,type: 'text' , sort: true },
                       {column: 'sales_installment_id',    title: _vm.$t('salesInstallmentsPayments.salesInstallment') ,type: 'linksalesInstallment', to:'salesInstallment', sort: true, link: true},
                       {column: 'description',    title: _vm.$t('salesInstallmentsPayments.thatsAbout') ,type: 'text' , sort: true },
                       {column: 'cost',    title: _vm.$t('salesInstallmentsPayments.amount') ,type: 'text' , sort: true },
                       {column: 'options', title: _vm.$t('salesInstallmentsPayments.settings'), type: 'options', options: [
                           {name: 'show'},
                           {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.sales_payments_edit},
                           {name: 'file'},
                           {name: 'delete', role: _vm.$user.admin || _vm.$user.role.sales_payments_delete} ]}
                     ],"deleteText":{
                      attention: _vm.$t('allerts.Attention'),
                      areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
                      close: _vm.$t('allerts.close'),
                      confirm: _vm.$t('allerts.confirm'),
                      loading: _vm.$t('allerts.loading'),
                     }}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }